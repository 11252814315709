/**
 * Ionic 4 Taxi Booking Complete App (https://store.enappd.com/product/taxi-booking-complete-dashboard)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
export const environment = {
  production: false,
  //add your firebase Project configuration
  // Take help from this Blog to setup firbase config:- https://enappd.com/blog/how-to-integrate-firebase-in-ionic-4-apps/23/
  config: {
    apiKey: "AIzaSyAHCjxulq5i08XNLfaOVzNan-TeqbYjzPY",
    authDomain: "maronas-aba00.firebaseapp.com",
    databaseURL: "https://maronas-aba00.firebaseio.com",
    projectId: "maronas-aba00",
    storageBucket: "maronas-aba00.appspot.com",
    messagingSenderId: "749582393114",
    appId: "1:749582393114:web:48fbb73bfb659a744316a3",
    measurementId: "G-QP6YRYEZLH"
  }
};
